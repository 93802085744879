<template>
<div
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/remainPickUp/cpBg.png') + ')',
      backgroundColor: headerBgColor,
    }"
    class="boxSty"
  >
    <van-nav-bar title="业务详情"  @click-left="goback()">
      
    </van-nav-bar>
  <!-- 逝者信息 -->
    <div class="deadInfo">
      <div class="head">
        <div class="header-bg">遗体外运</div>
      </div>
      <div class="info1">
        <div class="address">{{ model.deadName }}</div>
        <div class="address">{{ model.carNo }}</div>
        <div class="address">{{ model.taskEndTime }}</div>
        <div class="address" v-if="model.endPlace" @click="toNavigator(model)">
          {{ model.endPlace }}
          <img src="../../assets/images/remainPickUp/position-icon.png" />
        </div>
      </div>
      <div class="info2">
        <div class="line-item">
          <div class="label">逝者姓名</div>
          <div class="val-style">{{ model.deadName }}</div>
        </div>
        <div class="line-item">
          <div class="label">逝者身份证号</div>
          <div class="val-style">{{ model.deadCardCode }}</div>
        </div>
        <div class="line-item">
          <div class="label">经办人</div>
          <div class="val-style">{{ model.contactName }}</div>
        </div>
        <div class="line-item">
          <div class="label">联系电话</div>
          <div class="val-style">{{ model.contactPhone }}</div>
        </div>
        <div class="line-item">
          <div class="label">办理时间</div>
          <div class="val-style">{{ model.createTime }}</div>
        </div>
        <div class="line-item">
          <div class="label">接运司机</div>
          <div class="val-style">{{ model.staffs }}</div>
        </div>
      </div>
      <div class="businessFileBtn" @click="goBusinessFile()">
        <div>
          <img :src="iconBusinessFile" alt="" />
          <span>业务档案</span>
        </div>
        <div><van-icon name="arrow" /></div>
      </div>
    </div>

    <!-- 业务流程 -->
    <div class="business-process">
        <div class="title">业务流程</div>
        <div class="process">
           <div class="process-item">
               <div class="icon-container">
                  <div class="icon">
                     <img v-if="!model.startTime" src="../../assets/images/remainPickUp/todo.png"/>
                     <img v-if="model.startTime" src="../../assets/images/remainPickUp/finish.png"/>
                  </div>
                  <div class="dshaed-line"></div>
               </div>
               <div class="name-container">
                  <div class="name">外运出车</div>
                  <div class="status" v-if="!model.startTime">未开始</div>
                  <div class="status" v-if="model.startTime">操作员：{{model.staffs}} {{model.startTime}}</div>
               </div>
           </div>
           <div class="process-item">
               <div class="icon-container">
                  <div class="icon">
                     <img v-if="!model.finishTime " src="../../assets/images/remainPickUp/todo.png"/>
                     <img v-if="model.finishTime " src="../../assets/images/remainPickUp/finish.png"/>
                  </div>
                  <div class="dshaed-line"></div>
               </div>
               <div class="name-container">
                  <div class="name">遗体到达</div>
                 <div class="status" v-if="!model.finishTime ">未开始</div>
                  <div class="status" v-if="model.finishTime ">操作员：{{model.staffs}} {{model.finishTime }}</div>
               </div>
           </div>
           <div class="process-item">
               <div class="icon-container">
                  <div class="icon">
                      <img v-if="!model.endTime " src="../../assets/images/remainPickUp/todo.png"/>
                     <img v-if="model.endTime " src="../../assets/images/remainPickUp/finish.png"/>
                  </div>
                  <div class="dshaed-line"></div>
               </div>
               <div class="name-container">
                <div class="name">返回到馆</div>
                 <div class="status" v-if="!model.endTime ">未开始</div>
                  <div class="status" v-if="model.endTime ">操作员：{{model.staffs}} {{model.endTime }}</div>
               </div>
           </div>
        </div>
    </div>

    <!-- 操作按钮 -->
    <div class="operation-btn" v-if="prcessStatus == '1'" @click="submit">外运出车</div>
    <div class="operation-btn" v-if="prcessStatus == '2'" @click="subStart">遗体到达</div>
    <div class="operation-btn" v-if="prcessStatus == '3'" @click="subComplete">返回到馆</div>
  </div>
</template>
<script>
import { showConfirmDialog  ,showSuccessToast,showFailToast } from "vant";
import headerBg from "@/assets/images/ytjyDetailsBg.png";
import iconBusinessFile from "@/assets/images/icon-businessFile.png";
import cpBg from "@/assets/images/remainPickUp/cpBg.png";
import {
  ETansportDetails,
  ETansportOutCar,
  ETansportDelivery,
  ETansportPavilion,
} from "@/api/remainsPickUp";

export default {
  name: "details",
  data() {
    return {
      headerBg,
      cpBg,
      iconBusinessFile,
      headerBgColor: "#f3f4f6",
      // model: {},
      popupShow: false,
      energyShow: true,
      applyId: {},
      model: {
        deceasedBasicInfo: {},
        serviceInfo: {},
        serviceCostDetail: [],
        corpseVehicleDispatch: {},
        corpseVehicleSend:{},
      }, //基本信息里面包括了业务信息list
      carSelectShow: false, //车牌选择弹出框
      carNo: "", //选择的车牌
      staffId:this.$store.state.user.staffId,
      prcessStatus:''
    };
  },
  created() {
    let applyId = this.$route.query.applyId;
    this.applyId = applyId;
    // console.log("打印-----", applyId);
    
    this.getbusinessInfo();
  },
  methods: {
    goBusinessFile() {
      this.$router.push({
        path: "/remainETansport/businessFile",
        query: {
          deadId: this.model.deadId,
        },
      });
    },
    // 导航
    toNavigator(item){
      console.log(item);
      this.$router.push({path:'/navigator/navigator',query:{addr:item.endPlace}})
    },
    goback() {
      // 返回上一级
      this.$router.go(-1);
    },
    getbusinessInfo() {
      if (this.applyId) {
        ETansportDetails(this.applyId ).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.model = res.data;
            this.prcessStatus = res.data.status;
          } else {
            showFailToast(res.message);
          }
          // data
        });
      } else {
        console.log("跳转+++++");
        this.$router.push("/remainETansport");
      }
    },
    
    
    submit() {
      // 出车

     showConfirmDialog({
        title: "外运出车",
        message: "是否开始进行遗体外运业务",
      })
        .then(() => {
          // on confirm
          ETansportOutCar(this.model.applyId).then(
            (res) => {
              if (res.code == 200) {
                showSuccessToast("出车成功");
                this.getbusinessInfo();
              } else {
                showFailToast(res.message);
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    subStart() {
      // 送达
      showConfirmDialog({
        title: "遗体达到",
        message: "遗体是否已送达至外运地址",
      })
        .then(() => {
          // on confirm
          ETansportDelivery(this.model.applyId).then(
            (res) => {
              console.log(res);
              if (res.code == 200) {
                showSuccessToast("操作成功");
                this.getbusinessInfo();
              } else {
                showFailToast(res.message);
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },

    subComplete() {
      // 到馆
     showConfirmDialog({
        title: "返回到馆",
        message: "遗体外运业务是否完成",
      })
        .then(() => {
          // on confirm
          ETansportPavilion(this.model.applyId).then(
            (res) => {
              if (res.code == 200) {
                this.getbusinessInfo();
              } else {
                showFailToast(res.message);
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    
    
  },
};
</script>

<style scoped lang="less">
::v-deep .van-hairline--bottom::after {
  border: none;
}
::v-deep .van-nav-bar__title {
  color: #fff;
}
::v-deep .van-cell {
  padding: 0.08rem 0.42667rem;
}
::v-deep .van-cell::after {
  border: none;
}
::v-deep .van-nav-bar {
  background: none;
}
::v-deep .van-popup--center {
  top: 50%;
  left: 5%;
  -webkit-transform: translate3d(-2.5%, -50%, 0);
  transform: translate3d(-2.5%, -50%, 0);
  width: 90%;
  border-radius: 0.3rem;
}

.popupContent {
  .header {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 2rem;
    line-height: 2rem;
    color: #fff;
    text-align: center;
    font-size: 0.8rem;
  }
  .form {
    padding: 5%;
    .tipsText {
      font-size: 0.5rem;
    }
    .formModel {
      ::v-deep .van-cell {
        border: 1px solid #e6e6e6;
        border-radius: 5px;
      }
    }
  }
}
.boxSty {
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-x: hidden;
  height: inherit;
}
.deadInfo {
  width: 95%;
  padding: 0 12px;
  background-color: #fff;
  margin: 0.1rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  font-size: 18px;
  .head {
    text-align: center;
    height: 1rem;
    .header-bg {
      width: 96px;
      height: 26px;
      background: url("../../assets/images/remainPickUp/heade-tag.png")
        no-repeat;
      background-size: contain;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 26px;
      margin: 0 auto;
    }
  }
  .info1 {
    border-bottom: 1px solid #eeeeee;
    font-size: 18px;
    font-weight: bold;
    color: black;
    padding-bottom: 6px;
    .address {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      img {
        width: 21px;
        height: 21px;
      }
    }
    ::v-deep .van-icon {
      color: #898989;
      font-size: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  .info2 {
    padding: 10px 0;
    .line-item {
      display: flex;
  
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      height: 26px;
      .label { 
        color: #898989;
      }
      .val-style {
        color: #000000;
      }
    }
  }
}

.business-process{
  width: 95%;
  padding: 0 12px;
  background-color: #fff;
  margin: 0.3rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  font-size: 18px;
  margin-bottom: 60px;
  padding-bottom:14px ;
  .title{
    height: 52px;
    line-height: 52px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }
  .process{
    .process-item{
      display: flex;
      font-size: 14px;
      .icon-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon{
          width: 40px;
          height: 40px;
          background: url('../../assets/images/remainPickUp/process-icon.png');
          background-size: contain;
          position: relative;
          img{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 16px;
            height: 16px;
          }
        }
        .dshaed-line{
          height: 30px;
          width: 1;
          border-left: 1px dashed  #ccc;
        }
      }
      .name-container{
        margin-left: 10px;
        .name{
          color: black;
        }
        .status{
          color: rgba(137, 137, 137, 1);
          margin-top: 4px;
        }
      }
    }
  }
}

.operation-btn{
  position: fixed;
  left: 2.5%;
  bottom: 10px;
  width: 95%;
  margin: 0 auto;
  box-sizing: border-box;
  height: 41px;
  color: white;
  background: rgba(255, 154, 88, 1);
  text-align: center;
  line-height: 41px;
  border-radius: 5px;
  font-size: 16px;
}

 .businessFileBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    padding: 0.3rem 0;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    div:nth-child(1) {
      img {
        vertical-align: middle;
        width: 20%;
        height: 20%;
      }
      span {
        font-size: 0.4rem;
        margin-left: 0.2rem;
      }
    }
    div:nth-child(2) {
      font-size: 0.5rem;
      color: #898989;
    }
  }
.businessInfo {
  width: 95%;
  background-color: #fff;
  margin: 0.1rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  padding: 1% 0 2% 0;
  .head {
    width: 100%;
    font-size: 0.25rem;
    padding: 0.08rem 0.42667rem;
  }
  .content {
    margin-bottom: 00.5rem;
  }
}
.subBtn {
  width: 95%;
  margin: 0.1rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  padding: 1% 0 3% 0;
  text-align: center;

  button {
    width: 100%;
    border-radius: 0.2rem;
  }
}
.subCarNo {
  button {
    width: 100%;
  }
}
</style>